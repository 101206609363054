// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Update the CSRF field on Formie forms
import formieCsrf from './components/formie-csrf';

// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';

// Maps
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';

// Calendar
import FullCalendar from '@components/FullCalendar.vue';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        GoogleMapEmbed,
        FullCalendar,
    },

    data() {
        return {
            navActive: false,
            stickyNav: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        formieCsrf();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

        // Search focus state
        document.querySelector('[data-search-open]').addEventListener('click', (e) => {
            document.getElementById('search').focus();
        });

        // Header link drop down
        const headerDD = document.querySelectorAll('.js-show-dd');

        if (headerDD.length) {
            headerDD.forEach((e) => {
                const jsDD = e.querySelector('.js-dd');
                e.addEventListener('click', (e) => {
                    jsDD.classList.toggle('hidden');
                });
            });
        }

        // Virtual tour links
        const pin = document.querySelectorAll('.js-pin');
        const pinCards = document.querySelectorAll('.js-pin-card');

        if (pin.length) {
            pin.forEach((e) => {
                e.addEventListener('click', (event) => {
                    const pinId = e.dataset.pinid;
                    pinCards.forEach((element) => {
                        element.classList.add('hidden');
                    });

                    document.querySelector(`[data-pincardid="${pinId}"]`).classList.toggle('hidden');
                });
            });
        }

        // Site wide careers

        const careersDD = document.querySelector('.js-careers-select');
        const jobs = document.querySelectorAll('.js-job');

        if (careersDD) {
            careersDD.addEventListener('change', (event) => {
                const school = careersDD.value.substring(careersDD.value.lastIndexOf('#') + 1);

                if (jobs) {
                    jobs.forEach((job) => {
                        if (school == 'all') {
                            job.classList.remove('hidden');
                        } else {
                            job.classList.add('hidden');
                        }
                    });
                }

                const selectedSchool = document.querySelectorAll(`[data-job="${school}"]`);
                if (selectedSchool) {
                    selectedSchool.forEach((school) => {
                        school.classList.remove('hidden');
                    });
                }

            });
        }

        // Multiple Calendar JS
        const calendarDD = document.querySelector('.js-calendar-select');
        const allCalendars = document.querySelectorAll('.js-calendar');

        if (calendarDD) {
            calendarDD.addEventListener('change', (event) => {
                const calendarId = event.target.value;

                allCalendars.forEach((cal) => {
                    cal.classList.remove('show-cal');
                });

                document.querySelector(`.${calendarId}`).classList.add('show-cal');
            });
        }

        function scrollTo(hash) {
            location.hash = `#${hash}`;
        }
    },
}).mount('#app');
