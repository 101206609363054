<script>
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

export default {
    components: {
        FullCalendar, // make the <FullCalendar> tag available
    },

    props: {
        initialView: {
            type: String,
            default: 'dayGridMonth',
        },
        googleCalendarId: {
            type: String,
            default: 'richard@sgroup.com.au',
        },
    },

    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, listPlugin, interactionPlugin, googleCalendarPlugin],
                initialView: this.$props.initialView,
                googleCalendarApiKey: 'AIzaSyD9dfS5n8GHjU3DK-cYY6wtbgnegCQN650',
                events: {
                    googleCalendarId: this.$props.googleCalendarId,
                },
            },
        };
    },
};
</script>

<template>
    <FullCalendar :options="calendarOptions" />
</template>
